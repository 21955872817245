import { setRegisteredData } from "./forms/registered-data";

let voucher = '';

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('#form-application').on('submit-success', function(event, data) {
    $('.s-form-container').addClass('d-none');
    $('.s-form__decor-1').addClass('d-none');
    $('.s-form__decor-2').addClass('d-none');
    $('.s-form__decor-3').addClass('d-none');
    $('.s-form__decor-4').addClass('d-none');
    $('.s-form__decor-5').addClass('d-none');
    $('.s-form__decor-6').removeClass('d-none');
    $('.s-form__thanks-first').addClass('d-none');
    $('.s-form__thanks-other').addClass('d-none');

    if (data.type === 'first') {
        $('.s-form__thanks-first').removeClass('d-none');
        voucher = data.voucher;
        // $('.s-form__thanks-gifts .gift.selected .gift-item img').attr('src', data.voucher)
    } else {
        $('.s-form__thanks-other').removeClass('d-none');
    }

    $('html, body').animate({
        scrollTop: $('.s-form').offset().top - 100
    }, 500);
});

$('.s-form__thanks-gifts .gift').on('click', function (e) {
    if ($('.s-form__thanks-gifts .gift.selected').length) {
        e.preventDefault();
    } else {
        $(this).find('.gift-item img').attr('src', voucher);
        $(this).addClass('selected');
    }
});

$('.game-run').on('click', function() {
    // page swap
    $('.page').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.page-result').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-application').modal('show');
});

$('.result-try-again').on('click', function() {
    $('.page').addClass('d-none');
    $('.page-form').removeClass('d-none');
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[street]"]',
        '[name="personal_data[streetNr]"]',
        '[name="personal_data[flatNr]"]',
        '[name="personal_data[zipCode]"]',
        '[name="personal_data[city]"]',
    ];
    $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    setRegisteredData(data, 'section#winner');

    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-winner').modal('show');
});

$('.contact-form').on('submit-success', function(event, data) {
    setRegisteredData(data, 'section#contact');

    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-contact').modal('show');
});